.section1 {
    text-align: center;
    padding: 20px;
}

.section1 h2{
    font-size: 28px;
    font-weight: bold;
    color: #000000;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.grid p{
    color: black;
}

.imageWrapper {
    margin: 10px;
    cursor: pointer;
    transition: transform 0.3s;
}

.imageWrapper:hover {
    transform: scale(1.05);
}

.image {
    width: 500px;
    height: auto;
    max-width: 300px; /* Adjust as needed */
    border-radius: 10px;
}

.viewAllButton {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #ff4081; /* Change color as needed */
    color: white;
    cursor: pointer;
}

.viewAllButton:hover {
    background-color: #e63946; /* Change hover color as needed */
}
