.section2 {
  padding: 40px;
  text-align: center;
  background-color: #f9f9f9; /* Light background for contrast */
  border-radius: 8px; /* Rounded corners for the section */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.section2 h2 {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: bold;
  color: #000000; /* Darker color for the heading */
  font-family: 'Arial', sans-serif; /* Professional font */
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 items per row on mobile */
  gap: 20px; /* Space between items */
}

.flavorBox {
  background-color: #ffffff; /* White background for flavor boxes */
  border: 1px solid #2980b9; /* Blue border for flavor boxes */
  border-radius: 8px; /* Rounded corners */
  padding: 30px; /* Increased padding for better spacing */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s; /* Transition for hover effects */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for flavor boxes */
}

.flavorBox:hover {
  background-color: #2980b9; /* Change background color on hover */
  color: #ffffff; /* Change text color on hover */
  transform: translateY(-2px); /* Slight lift on hover */
}

.flavorBox:active {
  transform: translateY(0); /* Reset lift on click */
}

/* Responsive design for larger screens */
@media (min-width: 600px) {
  .grid {
      grid-template-columns: repeat(4, minmax(200px, 1fr)); /* Responsive grid */
  }
}

@media (max-width: 600px) {
  h2 {
      font-size: 24px; /* Smaller font size for mobile */
  }
  
  .flavorBox {
      padding: 20px; /* Less padding on smaller screens */
  }
}
