.profileMenu {
  position: relative;
  display: inline-block;
}

.dropdownMenu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffc9c9; /* Change to white background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
  width: 200px;
}

.profileMenu.open .dropdownMenu {
  display: block;
}

.dropdownMenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdownMenu li {
  border-bottom: 1px solid black; /* Light grey border for separation */
}

.dropdownMenu li:last-child {
  border-bottom: none;
}

.dropdownMenu a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #000; /* Black text color */
}

