.section3Container {
    text-align: center;
    padding: 20px;
}

.section3Title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #000000;
}

.section3Grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 20px; /* Space between images */
    margin-bottom: 20px;
}

/* Wrapper for each image and its label */
.section3ImageWrapper {
    cursor: pointer;
    transition: transform 0.3s ease;
    text-align: center;
}

.section3ImageWrapper:hover {
    transform: scale(1.05);
}

/* Image styling */
.section3Image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Label styling */
.section3ImageLabel {
    margin-top: 10px;
    font-size: 16px;
    color: #000000;
    font-weight: 500;
}

/* View All Button styling */
.section3ViewAllButton {
    padding: 10px 20px;
    font-size: 16px;
    border: 2px solid #ff4081;
    border-radius: 30px;
    background-color: transparent;
    color: #ff4081;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.section3ViewAllButton:hover {
    background-color: #ff4081;
    color: white;
}
