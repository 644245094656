.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loaderOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.cartContainer {
  background-color: #fff;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
  max-height: 90%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.cartHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.cartItems {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}

.cartItem {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px 0;
  border-bottom: 1px solid #e0e0e0;
}

.cartItem:last-child {
  border-bottom: none;
}

.itemImage {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
}

.itemInfo {
  flex-grow: 1;
}

.itemName {
  font-size: 16px;
  font-weight: bold;
}

.itemDescription {
  color: #666;
  font-size: 14px;
}

.priceContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.originalPrice {
  text-decoration: line-through;
  color: #999;
}

.discountPrice {
  color: #d9534f;
  font-weight: bold;
}

.quantityControls {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.quantityControls button {
  width: 30px;
  height: 30px;
  border: 1px solid #e0e0e0;
  background: pink;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
}

.quantityControls button:hover {
  background: rgb(252, 141, 160);
}

.removeItem {
  background: none;
  border: none;
  color: #d9534f;
  font-size: 20px;
  cursor: pointer;
}

.savedAddress {
  background-color: #e9f5ff;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.savedAddress h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
}

.savedAddress {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}

.savedAddress p {
  margin: 5px 0;
}

.addressSection {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addressSection h3 {
  margin-bottom: 10px;
}

.addressForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%; /* Ensure the form takes the full width */
}

.addressForm input {
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
}

.locationButton {
  margin-top: 10px;
  padding: 10px;
  border: none;
  background-color: #5bc0de;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  width: 100%; /* Make the button full width */
  max-width: 300px; /* Optional: Limit the button width */
}

.locationButton:hover {
  background-color: #31b0d5;
}

.saveDetailsButton, .editAddressButton {
  margin-top: 10px;
  padding: 10px;
  border: none;
  background-color: #5bc0de; /* Default color for saveDetailsButton */
  color: white;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  width: 100%; /* Full width of the container */
  max-width: 300px; /* Optional: Limit the button width */
  margin-left: auto; /* Center the button horizontally */
  margin-right: auto; /* Center the button horizontally */
}

.editAddressButton {
  background-color: #f0ad4e; /* Specific color for editAddressButton */
}

.saveDetailsButton:hover, .editAddressButton:hover {
  background-color: #31b0d5; /* Adjust for saveDetailsButton */
}

.editAddressButton:hover {
  background-color: #ec971f; /* Adjust for editAddressButton */
}

.paymentDetails {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
}

.paymentDetails div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.totalAmount {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  color: #d9534f;
  margin-top: 10px;
}

.checkoutButton {
  padding: 15px;
  border: none;
  background-color: #d9534f;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.checkoutButton:hover {
  background-color: #c9302c;
}

.errorText {
  margin: 5px 0;
  color: red;
}

.separator {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.separatorLine {
  flex-grow: 1;
  height: 1px;
  background-color: #666; /* Adjust the color as needed */
}

.separatorText {
  margin: 0 10px;
  font-weight: bold;
  color: #666;
  white-space: nowrap; /* Prevents the text from wrapping */
}


.paymentModeSection {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
}

.paymentModeSection h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
}

.paymentModeSection label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  color: #666;
}

.paymentModeSection input[type="radio"] {
  margin-right: 10px;
}

.errorText {
  color: red;
  margin-top: 10px;
}

.additionalMessageSection {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
  text-align: center;
}

.additionalMessageSection h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
}

.additionalMessageInput {
  font-family: Arial, Helvetica, sans-serif;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  height: 50px;
  resize: vertical;
}

.couponSection {
  padding: 1.5rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 2rem 0;
}

.couponSection h3 {
  font-size: 1.17rem;
  margin-bottom: 1rem;
  color: #000000;
}

.couponInputContainer {
  display: flex;
  flex-direction: column;
}

.couponInputWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.couponInput {
  padding: 0.75rem 1rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  width: 100%;
  font-size: 1rem;
  transition: border 0.3s ease;
}

.couponInput:focus {
  border-color: #007bff;
  outline: none;
}

.couponActionButton {
  padding: 0.25rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 15px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.couponActionButton:hover {
  background-color: #0056b3;
}

.couponAppliedMessage {
  margin-top: 1rem;
  background-color: #e0ffe0;
  border-radius: 6px;
  padding: 0.75rem 1rem;
  color: #2e7d32;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  animation: fadeIn 0.3s ease-in;
}

.couponAppliedMessage p {
  margin: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.couponMessage {
  margin-top: 1rem;
  color: #ff4d4d;
  font-size: 0.875rem;
}


/* Wrapper for the order type buttons */
.orderTypeButtons {
  margin-top: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-align: center;
}

/* Label styling */
.orderTypeButtons label {
  font-size: 1.2rem;
  color: #333333;
  margin-bottom: 10px;
  display: block;
}

/* Button group container */
.buttonGroup {
  display: flex;
  justify-content: space-around;
  gap: 15px;
}

/* Styling for the buttons */
.button {
  padding: 5px 10px;
  font-size: 1rem;
  color: #333333;
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

/* Icon styling */
.icon {
  font-size: 24px;
  /* Size of Font Awesome icon */
  margin-bottom: 5px;
  /* Spacing between icon and text */
}

/* Selected button style */
.selected {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Hover effect for buttons */
.button:hover {
  border-color: #999999;
}

/* Error text styling */
.errorText {
  color: red;
  margin-top: 5px;
  font-size: 0.9rem;
}

/* Styles for the popup */
/* Styles for the popup */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Darker overlay for better focus */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the popup appears above everything else */
}

.popupContent {
  background-color: white;
  padding: 1.5rem; /* Reduced padding for better mobile view */
  border-radius: 12px;
  width: 95%; /* Max width for responsiveness */
  max-width: 450px; /* Limit width to prevent it from being too large on larger screens */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Professional shadow effect */
  z-index: 1010;
  max-height: 80vh; /* Limit the height to make it scrollable */
  overflow-y: auto; /* Scrollbar for when content exceeds the popup height */
  position: relative; /* Needed for positioning close button */
}

.popupContent h4 {
  margin-bottom: 1.2rem;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  color: #333;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 0.75rem;
}

.popupContent ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.popupContent ul li {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1.2rem;
  padding: 1rem;
  background-color: #f7f7f7;
  border-radius: 8px;
  border: 1px solid #ddd;
  transition: background-color 0.3s;
}

.popupContent ul li:hover {
  background-color: #f1f1f1;
}

.couponDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.couponCode {
  font-weight: bold;
  font-size: 1.2rem;
  color: #007bff;
  margin-bottom: 0.5rem;
}

.couponSpecification {
  font-size: 1rem;
  color: #555;
}

.applyButton {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 0.75rem; /* Space between coupon details and button */
  align-self: flex-end; /* Align button to the right */
  transition: background-color 0.3s;
}

.applyButton:hover {
  background-color: #218838;
}

/* Close button */
.couponCloseButton {
  position: absolute; /* Absolute positioning to place it in the corner */
  top: 1rem; /* Positioning from the top */
  right: 1rem; /* Positioning from the right */
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.couponCloseButton:hover {
  background-color: #c82333;
}

.scrollablePopup {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 1rem;
}

/* Optional: Customize the scrollbar for better user experience */
.popupContent::-webkit-scrollbar {
  width: 8px;
}

.popupContent::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 10px;
}


.couponHint {
  font-size: 1rem;
  color: #007bff;
  cursor: pointer;
  text-align: left;
  margin-bottom: 1rem; /* Space between the hint and the rest of the content */
  display: inline-block;
  transition: color 0.3s;
  text-decoration: underline; /* Optional: Adds an underline to indicate it's clickable */
}

.couponHint:hover {
  color: #0056b3;
}

.addressPopupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto; /* Allows the overlay itself to be scrollable if needed */
}

.addressPopupContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  max-height: 80vh; /* Constrain the height to 80% of the viewport height */
  overflow-y: auto; /* Scroll the content if it overflows */
}

.addressList {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 60vh; /* Set maximum height for the list to make it scrollable */
  overflow-y: auto; /* Scrolls when there are too many addresses */
}

.addressItem {
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  cursor: pointer;
}

.addressItem:hover {
  background-color: #f0f0f0;
}

.addressClosePopupButton {
  background: #e74c3c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.selectAddressButton {
  align-items: center;
  background-color: #5bc0de;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  gap: 5px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 300px;
  padding: 10px;
  width: 100%;
}

.selectAddressButton:hover {
  background-color: #2980b9;
}

.scheduleOrderSection {
  margin: 20px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.scheduleOrderSection h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
}

.dateTimeInput {
  width: 95%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff;
}

.dateTimeInput:focus {
  border-color: #007bff;
  outline: none;
}