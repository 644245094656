/* Header styles */
.header {
  background-color: #ffc9c9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 60px;
}

.logo img {
  height: 80px;
}

.headerTitle {
  font-size: 2rem;
  font-weight: bold;
  font-family: 'Georgia', serif;
  letter-spacing: 1px;
  text-decoration: none;
  background: linear-gradient(to right, #7B3F61, #BF5A84, #D98E8B, #E3A679, #C1916D, #8A678B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

a {
  text-decoration: none;
}


/* Desktop menu styles */
.navDesktop {
  display: flex;
  align-items: center;
}

.navDesktop ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navDesktop ul li {
  margin: 0 10px;
  display: flex;
  /* Align items horizontally */
  align-items: center;
  /* Vertically center items */
}

.navDesktop ul li a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.profileMenu {
  position: relative;
}

/* Mobile menu styles */
.navMobile {
  display: none;
}

.menuIcon {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
}

.menuIcon div {
  width: 25px;
  height: 3px;
  background-color: black;
}

/* Animation keyframes */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile menu styles */
.mobileMenu {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background-color: #ffc9c9;
  z-index: 1000;
  text-align: center;
  animation: slideDown 0.3s ease-out;
  /* Apply animation */
}

.mobileMenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobileMenu ul li {
  padding: 10px 0;
}

.mobileMenu ul li a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

/* Toggle switch container */
.toggleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Center the container in mobile view */
  margin: 10px 0;
  /* Add margin for spacing in mobile view */
}

/* The switch - hidden checkbox */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-right: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Slider - the toggle */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

/* Slider before (circle inside the switch) */
.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: white;
  transition: .4s;
  top: 4px;
  left: 4px;
}

/* When the checkbox is checked, slide the slider */
input:checked+.slider {
  background-color: cornflowerblue;
}

/* Move the slider circle to the right */
input:checked+.slider:before {
  transform: translateX(14px);
}

/* Status text styles */
.statusText {
  font-weight: bold;
}

.statusOnline {
  color: rgb(1, 143, 1);
}

.statusOffline {
  color: red;
}

@media (max-width: 768px) {
  .navDesktop {
    display: none;
  }

  .navMobile {
    display: block;
  }
}