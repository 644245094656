.sectionFive {
    text-align: center;
    padding: 40px 20px;
    background-color: #f9f9f9; /* Adjust to match the background */
}

.sectionFiveTitle {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
    color: #000000;
}

.sectionFiveGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px; /* Space between items */
}

/* Styles for image wrapper */
.imageWrapperFive {
    width: 180px; /* Adjust width as per your needs */
    cursor: pointer;
    transition: transform 0.3s ease;
    text-align: center;
}

.imageWrapperFive:hover {
    transform: scale(1.05);
}

.imageFive {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.imageLabelFive {
    margin-top: 10px;
    font-size: 16px;
    color: #555;
    font-weight: 500;
}

/* Media query for responsive design */
@media (max-width: 768px) { /* Adjust max-width as needed */
    .sectionFiveGrid {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items */
    }

    .imageWrapperFive {
        width: 100%; /* Full width for mobile */
        max-width: 300px; /* Optional: set a max width */
    }
}
