.sectionSeven {
    text-align: center;
    padding: 40px 20px;
    background-color: #f9f9f9; /* Adjust as needed */
    overflow: hidden; /* Prevents overflow of animated elements */
}

.sectionSevenTitle {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
    color: #000000;
}

.imageContainer {
    display: flex; /* Use flexbox to arrange images in a row */
    animation: move 20s linear infinite; /* Animation effect */
}

.imageSlide {
    min-width: 200px; /* Set minimum width for each image */
    margin-right: 20px; /* Space between images */
    cursor: pointer;
    transition: transform 0.3s ease;
}

.imageSlide:hover {
    transform: scale(1.05); /* Scale effect on hover */
}

.image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Mobile styles */
@media (max-width: 768px) {
    .sectionSevenTitle {
        font-size: 24px; /* Smaller title font size */
        margin-bottom: 20px; /* Reduce bottom margin */
    }

    .imageContainer {
        flex-direction: column; /* Stack images vertically */
        animation: none; /* Disable animation for mobile */
    }

    .imageSlide {
        min-width: 100%; /* Full width for mobile */
        margin-right: 0; /* Remove right margin */
        margin-bottom: 20px; /* Add space between images */
    }
}
