/* src/components/PrivacyPolicy.module.css */
.ppSection {
    position: relative;
    text-align: center;
    color: white;
    background: url('../../Images/cake1.jpg') no-repeat center center;
    background-size: cover;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ppOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.ppText {
    position: relative;
    font-size: 2rem;
    font-weight: bold;
}

.container {
    padding: 20px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .intro {
    font-size: 1.2em;
    margin-bottom: 20px;
    color: #555;
  }
  
  .sectionTitle {
    font-size: 1.5em;
    margin-top: 20px;
    color: #222;
  }
  
  .subsectionTitle {
    font-size: 1.2em;
    margin-top: 15px;
    color: #444;
  }
  
  .sectionContent {
    font-size: 1em;
    margin-top: 10px;
    line-height: 1.6;
    color: #444;
  }
  
  ul {
    margin: 10px 0;
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 10px;
  }
  